



























































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Contact, SMS } from "@/models/Sms";
import Vue from "vue";

import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import BtnMakeCall from "@/views/CallCenter/buttons/BtnMakeCall.vue";
import formatPhone from "@/utils/formatPhone";

export default Vue.extend({
  name: "chat-person-list",
  components: { BtnMakeCall },
  data() {
    return {
      selectedPerson: null,
      showOnline: false,
      interval: null,
      loading: false,
      chatHeight: 0,
      phoneNumber: "",
      phoneText: "",
      loadempl: false,
      query: "",
    };
  },
  computed: {
    ...mapGetters("crmSMSModule", ["messagesPerPatient"]),
    ...mapState(["profile"]),
    ...mapGetters(["isAdmin", "isSuper", "getPhone"]),
    ...mapState("crmSMSModule", [
      "messages",
      "contacts",
      "unreads",
      "contactSelect",
      "numberSelect",
    ]),
    ...mapState("crmEmployeeModule", ["workers"]),
    _showWorkers() {
      if (this.workers.length == 0) {
        return [];
      }
      const filt = this.workers.filter(
        (w: any) => w.phone != null && w.phone != undefined
      );
      return filt;
    },

    _numbercontact() {
      if (this.contactSelect == null) {
        return "";
      }
      return this.contactSelect.number;
    },

    contactSmsList() {
      return Array.from(this.messagesPerPatient.keys());
    },

    isAdministrator() {
      return this.isAdmin || this.isSuper;
    },
  },
  async mounted() {
    (this as any)._getNumberContacts();
    (this as any).loadempl = true;
    if (this.numberSelect == null) {
      (this as any).initRequestContacts();
    }
    await (this as any).getWorkers();
    (this as any).loadempl = false;
  },
  destroyed() {
    (this as any).mutnumberSelect(null);
    (this as any).mutSetContact(null);
    clearInterval((this as any).interval);
  },

  watch: {
    selectedPerson(val) {
      if (val != undefined && val != null) {
        const contac = this.contacts.filter((c: Contact) => c.number == val);
        if (contac.length > 0) {
          (this as any).setContact(contac[0]);
        }
      }
    },
    /* contactSelect: {
      handler() {
        (this as any).getsmsFiltered();
      },
    }, */

    numberSelect(val) {
      if (val == null) {
        (this as any).phoneNumber("");
        (this as any).phoneText("");
        (this as any).initRequestContacts();
      } else {
        clearInterval((this as any).interval);
      }
    },

    phoneNumber(val) {
      if (val != null && val != undefined && val != "") {
        if (val[0] != "+") {
          val = "+1" + val;
        }

        (this as any)._getNumberContacts(val);
        (this as any).mutnumberSelect(val);
      } else {
        if ((this as any).phoneText == "" || (this as any).phoneText == null) {
          (this as any)._getNumberContacts();
          (this as any).mutnumberSelect(null);
          (this as any).mutSetContact(null);
        }
      }
    },
    phoneText(val) {
      if (val != null && val != undefined && val != "" && val.length == 10) {
        if (val[0] != "+") {
          val = "+1" + val;
        }

        (this as any)._getNumberContacts(val);
        (this as any).mutnumberSelect(val);
      } else if (val != null && val.length > 0 && val.length < 10) {
        //nada
      } else {
        if (
          (this as any).phoneNumber == "" ||
          (this as any).phoneNumber == null
        ) {
          (this as any)._getNumberContacts();
          (this as any).mutnumberSelect(null);
          (this as any).mutSetContact(null);
        }
      }
    },
  },

  methods: {
    ...mapActions("crmSMSModule", ["actContac"]),
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),
    ...mapMutations("crmSMSModule", [
      "mutSetContact",
      "mutnumberSelect",
      "mutcontacts",
      "mutSetMessagesFilters",
    ]),

    initRequestContacts() {
      (this as any).interval = setInterval(
        (this as any)._getNumberContacts,
        4000
      );
    },

    resetFilter() {
      (this as any).phoneNumber = "";
    },

    setHeight() {
      (this as any).chatHeight = this.$vuetify.breakpoint.height;
    },

    async _getNumberContacts(number?: string) {
      let phone = number
        ? number
        : this.getPhone != null && this.getPhone != undefined
        ? this.getPhone
        : null;
      if (phone != null) {
        if (phone.includes("+1")) {
          phone = phone.replace("+1", "");
        }
        phone = "+1" + formatPhone(phone);

        await (this as any).actContac(phone);
      } else {
        (this as any).mutcontacts([]);
      }
    },
    noReadSms(contact: Contact) {
      const noread = this.unreads.filter(
        (ms: any) => ms.from == contact.number
      );
      if (noread.length != 0) {
        return noread[0].cant;
      }
      return 0;
    },
    async getWorkers() {
      await (this as any).actGetEmployees();
    },

    setContact(account: Contact) {
      (this as any).mutSetContact(account);
      this.$emit("contact", account);
    },
  },
});
