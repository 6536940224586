var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n    message-container-send\n    mt-1\n    d-flex\n    align-center\n    justify-end\n    position-relative\n  "},[_c('v-sheet',{staticClass:"message-send d-flex flex-column",class:{
      first: _vm.first,
      last: _vm.last,
      middle: _vm.middle,
    }},[(_vm.medias.length != 0)?[_vm._l((_vm._imagesShow),function(media,index){return _c('v-sheet',{key:index,staticClass:"imagencard",attrs:{"dark":"","elevation":"1","height":media.typeFile != 'image' ? '160' : '150',"width":"250"}},[(media.typeFile == 'image')?[_c('v-img',{attrs:{"alt":"No Image","height":"150","width":"250","contain":"","src":media.url},on:{"click":function($event){return _vm.dialogImg(media.url)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)})]:_vm._e(),(media.typeFile == 'audio')?[_c('vuetify-audio',{attrs:{"file":media.url,"color":"success","downloadable":""}})]:_vm._e(),(media.typeFile == 'video')?[_c('video',{attrs:{"width":"245","height":"145","controls":""}},[_c('source',{attrs:{"src":media.url,"type":media.typeFile + '/' + media.type}})])]:_vm._e(),[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"botondownload",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.downloadUrl(media)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}],null,true)},[_c('span',[_vm._v("Download Image")])])]],2)}),_c('v-dialog',{attrs:{"width":_vm.width},model:{value:(_vm.dialogImgShow),callback:function ($$v) {_vm.dialogImgShow=$$v},expression:"dialogImgShow"}},[_c('v-card',{staticClass:"imagencard"},[_c('v-img',{attrs:{"max-height":600,"max-width":_vm.width,"src":_vm.image,"contain":"","alt":"No image"},on:{"mousewheel":_vm.zoom}})],1)],1)]:_vm._e(),_c('span',{domProps:{"textContent":_vm._s(_vm.message)}}),_c('small',{staticClass:"text-right",domProps:{"textContent":_vm._s(_vm.longTime(_vm.createAt))}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }